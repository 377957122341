html,
body,
div#root {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: clip;
}

/* used for PDF rendering mode */
div#root.renderPDF {
  overflow: auto;
  height: auto;
}

body.renderPDF {
  height: auto;
  overflow: auto;
}

html.renderPDF {
  height: auto;
  overflow: auto;
}

/* used for intercom tooltips */
#intercom-tooltips-container {
  div[role=tooltip] {
    max-width: 410px;
    border-radius: 16px;
    div {
      padding-top:0;
      padding-bottom:0;
    }
  }

  h2 {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 700;
    color: #042441;
  }

  .intercom-block-paragraph {
    font-family: "Montserrat";
    font-size: 14px;
    color: #042441;
    text-align: justify;
  }

  a {
    color: #1F6CB3;
    font-family: "Montserrat";
    font-weight: 700;
  }
  
  div[data-popper-placement="right"] {
    div {
      padding-right: 0;
    }
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 16px solid rgb(255, 255, 255);
    padding: 0 8px 6px 9px;

  }
  div[data-popper-placement="left"] {
    div {
      padding-left: 0;
    }
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 16px solid rgb(255, 255, 255);
    padding: 0 9px 6px 8px;
  }
  div[data-popper-placement="top"] {
    div {
      padding-left: 0;
      padding-right: 0;
    }
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid rgb(255, 255, 255);
    padding: 0 8px 22px 8px;
  }
  div[data-popper-placement="bottom"] {
    div {
      padding-left: 0;
      padding-right: 0;
    }
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid rgb(255, 255, 255);
    padding: 16px 8px 8px 8px;
  }
}
